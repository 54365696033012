.endorsementform .form-container {
    background-color: white;
    padding: 20px;
    border: 1px darkgray solid;
    border-radius: 10px;
    /* max-width: 600px; */
    margin: 0 auto;
    color: black;
  }
  
  .endorsementform .form-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .endorsementform .form-tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: white;
    border: 1px solid lightgrey;
    color: #000;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .endorsementform .form-tab.active {
    background-color: #8CC14AFF;
    color: #0A4D20FF;
  }
  
  .endorsementform .form-body {
    display: flex;
    flex-direction: column;
  }
  
  .endorsementform .form-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    text-align: left;
  }
  
  .endorsementform .form-group {
    flex: 1 1 48%;
   
  }
  .endorsementform .form-group1 {
    flex: 1 1 100%;
   
  }
  
  .endorsementform .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .endorsementform .form-control {
    width: 100%;
    padding: 10px;
    /* border: none; */
    border-radius: 5px;
  }
  
  .endorsementform .form-submit-button {
    padding: 10px 20px;
    /* background-color: ; */

    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  /* .form-submit-button:hover {
    background-color: #16a085;
  } */
  
  .endorsementform .suggestions {
    position: absolute;
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: auto;
  }
  .view-more{
    cursor: pointer !important;
  }
  .endorsementform .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .endorsementform .suggestion-item:hover {
    background-color: #eee;
  }
  