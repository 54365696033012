.form-select > option{
 color: black !important;
}
.form-select{
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='White' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");;
}
.footer-bottom{
    display: flex;
    justify-content: center;

}
.logo-width{
    max-width: 110px !important;
}
@media screen and (max-width: 768px) {
    .footer-bottom{
       flex-direction: column;
        gap: 10px;
        
    }
    .logo-width{
        max-width: 135px !important;
    }
}