.organization-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .organization-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .org-bg{
    background-image: url("../../img/Group.webp");
    background-size: 100%;
    /* height: 50vh; */
    
  }
  .org-name{
    font-size: 4rem;
  }
  .organization-logo {
    width: 250px;
    height: 250px;
    
    border-radius: 10px;
    margin:100px  20px 0;
  }
  
  .organization-name {
    font-size: 28px;
    font-weight: bold;
  }
  
  .organization-section {
    margin-top: 30px;
  }
  
  .organization-social-links {
    list-style-type: none;
    padding: 0;
  }
  
  .organization-social-links li {
    margin: 5px 0;
  }
  
  .initiatives-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .initiative-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .initiative-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .org-bg-mobile{
    display: none !important;
  }
  @media screen and (max-width: 768px) {
    .organization-logo {
      width: 250px;
      height: 250px;
      
      border-radius: 10px;
      margin:20px ;
    }
    .org-bg{
      display: none !important;
    }
    .org-bg-mobile{
      display: unset !important;
    }
  }


  /* slider css */
  .card-img-top-slider{
    aspect-ratio: 1/1;
    height: 200px;
    object-fit: contain;
    margin: 0 auto;
  }
  .small-card-height-slider {
    height: 440px;
  }