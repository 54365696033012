.carousel-control-next-icon{
    background-image: url("../../img/rightarrow.png") !important;
}
.carousel-control-prev-icon{
    background-image: url("../../img/leftarrow.png") !important;
}
.carousel-control-next, .carousel-control-prev{
    width: 7.5% !important;
}

.slide{
  border-top: 0px;
  padding-top: 0%;
  margin-top: 0%;
}

.herotitle{
  color: #0f8240;
  font-size: 38px;
  width: 100%;
  text-align: center;
  
  /* background-color: rgba(255,255,255,0.3); */

}
.herodesc{
  color: #0f8240;
  font-size: 20px;
  width: 100%;
  text-align: center;
  /* background-color: rgba(255,255,255,0.3); */
}
.know-more{
    font-size:normal !important;
    font-weight: 500 !important;
    bottom: 0;
    left: 50%;
    translate: -50%;
}

/* leader slider */
/* Add this to your CSS file */
.leader-carousel {
    text-align: center;
    margin: 50px 100px !important;
  }
  .leader-carousel h2 {
    color: #0C7A31FF;
  }
  .leader-carousel .card {
    margin: 0 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .leader-carousel .card img {
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .leader-carousel .card-body {
    text-align: left;
  }
  
  .leader-carousel .card-title {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .leader-carousel .card-subtitle {
    font-size: 1rem;
    color: #777;
  }
  
  .leader-carousel .card-text {
    font-size: 0.9rem;
    margin-top: 10px;
  }
  
  .leader-carousel .social-links {
    margin-top: 15px;
  }
  
  .leader-carousel .social-links a {
    margin: 0 10px;
    color: #007bff;
    font-size: 1.2rem;
  }
  .org-carousel .social-links {
    margin-top: 15px;
  }
  
  .org-carousel .social-links a {
    margin: 0 10px;
    color: #007bff;
    font-size: 1.2rem;
  }

  .leader-carousel .slick-prev,.leader-carousel  .slick-next {
    width: 30px !important;
    height: 30px !important;
  }
.slick-prev:before, .slick-next:before{
  content: "" !important;
}
  /* mission section */
  .mission-section {
    position: relative;
    height: 500px;
    background-image: url('../../img/ourmissionbg.webp'); /* Replace with actual image path */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    color: white;
  }
  
  .mission-content {
    max-width: 550px;
    margin: 0 28px;
    padding: 20px;
    /* background-color: rgba(0, 128, 0, 0.8); Semi-transparent green */
    border-radius: 10px;
  }
  
  .mission-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .mission-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #45a049;
  }
  
/* Pulse effect */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader-container img {
  width: 200px; /* Adjust size as needed */
  animation: pulse 1.5s infinite;
}



  @media screen and (max-width: 768px) { 
    .leader-carousel {
      text-align: center;
      margin: 50px 10px !important;
    }
    .herotitle{
      font-size: 22px;
    }
    .mission-section {
      background-position: right;
    }
    .mission-content{
      background-color: rgba(0,0,0,0.5) ;

    }
  } 