.initiative-img{
    aspect-ratio: 16/6;
    object-fit: contain;
    height: 35vh;
}
.initiative-width{
    width: 65%;
}
.heading-img{
    background-image: url("../../img/initiativebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 350px;
}
.heading-img h1{
    font-size: 52px !important;
    text-align: start;
    
}
.initiative-header> img {
    height: 60vh;
    aspect-ratio: 16/6;
    object-fit: cover;
   /* width: 100%; */
  }
  
  .initiative-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .initiative-header p {
    font-size: 1.25rem;
  }
  
@media screen and (max-width: 768px) {
    .initiative-header{
        padding-top:  0 !important;
        padding-bottom:  0 !important;
    }
    .initiative-header> img {
        height: auto;
        aspect-ratio: 16/6;
        object-fit: contain;
       width: 100%;
      }
    .initiative-img{
        aspect-ratio: 16/6;
        object-fit: contain;
        height: auto;
    }
    .heading-img h1{
        font-size: 52px !important;
       margin-top: 20%;
    
    }
    .heading-img{
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../img/initiativebg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
        height: 350px;
    }
}