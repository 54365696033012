.about-heading {
  color: #0c7a31ff !important;
}
.youtube-width {
  width: 50%;
  height: 400px !important;
}
.visit-cam {
  color: #0c7a31ff;
  position: relative;
}
h2 {
  margin-top: 0;
  padding-top: 10px; /* Adjust as necessary */
}
.map-container {
  /* flex: 0 0 30%;  */
  height: 100%;
  padding: 0;
  margin: 0;
}
.leaflet-container {
  width: 100%;
  height: 100%;
}

.about-container {
  flex: 0 0 70%;
  padding: 0 15px;
  margin: 0;
}

.container p {
  font-size: 16px;
  color: #666;
}
.endorsement-img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 110px;
  object-fit: contain;
}
.visit-cam::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 42.5%;
  width: 15%;
  height: 2px;
  background-color: darkgreen;
}



.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
}

.slick-prev {
  left: 0 !important;
  z-index: 99;
}
.slick-next {
  right: 0 !important;
}
.text-editor .ck.ck-editor__editable_inline {
  border: none !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background-color: rgba(222, 225, 230, 0.9);
  text-align: justify;
}
.text-editor .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: none !important;
}
.modal-img{
  max-width: 95%;
}
/* .container {
    display: flex;
    flex-direction: row;
    align-items: start; 
    justify-content: space-between; 
  } */
  .thank-you-message {
    margin-top: 50px;
  }
  
  .thank-you-message h2 {
    color: #28a745;
    font-weight: bold;
  }
  
.flex-item {
  flex: 1; /* Each child takes equal space */
  padding: 15px; /* Optional padding for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}
.d-flex {
  justify-content: space-between; /* Change to 'flex-start' or remove if causing issues */
  align-items: start; /* Ensures alignment at the top */
}
.btn-left-bg {
  background-color: transparent;
  border: 0;
  background-image: url("../../img/leftbtn.png");
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  height: 90px;
  width: 300px;
}
.btn-right-bg {
  background-color: transparent;
  border: 0;
  background-image: url("../../img/rightbtn.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  height: 90px;
  width: 300px;
}
.btn-left-bg:hover,
.btn-left-bg:active {
  border: 0;
}
.btn-right-bg:hover,
.btn-right-bg:active {
  border: 0;
}
.name-width {
  width: 175px;
}
.flex-grow-1 {
  flex: 1;
  padding: 15px;
}
.feeds-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 0 auto !important;
}
.custom-col-5{
  width: 20%;
  float: left;
}
@media (min-width: 1200px) {
  .custom-col-5 {
    width: 20% !important;
    float: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    width: 33.33% !important;
    float: left;
  }
}



@media (min-width: 768px) and (max-width: 991px) {
  .col-md-4 {
    width: 50%;
    float: left;
  }
}

@media (max-width: 767px) {
  .col-sm-6 {
    width: 100%;
    float: left;
  }
}

.post {
  border: 1px solid #ddd;
  padding: 10px;
  width: calc(33% - 20px); /* Adjust width to fit three items per row */
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.post img, .post video {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  aspect-ratio: 1/1;
}
.post p {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}
.post a {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}
.post a:hover {
  text-decoration: underline;
}

.nextprevbutton-mobile {
  display: none !important;
}


.custom-nav-tabs {
  display: flex;
  justify-content: flex-start;
  border-bottom: none;
}

.custom-nav-link {
  position: relative;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.custom-nav-link.active {
  color: green; /* Change color to green if needed */
}

.custom-nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: green; /* Green underline for the active tab */
}

.custom-nav-link:not(.active)::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  width: 0;
  height: 3px;
  background-color: transparent;
  transition: width 0.3s ease;
}

.custom-nav-link:hover::after {
  width: 100%;
  background-color: green;
}

.leadergalleryimg{
  aspect-ratio: 1/1;
}

.event-txt-align{
  text-align: end;
}
.event-poster{
  aspect-ratio: 16/7;
  object-fit: contain;
  /* height: 75vh; */
}
@media screen and (max-width: 768px) {
  .event-poster{
    aspect-ratio: 16/7;
    object-fit: contain;
    height: unset !important;
  }
  .event-txt-align{
    text-align: start;
  }
  .post {
    width: calc(100% - 20px); /* Adjust width to fit one item per row */
  }
  .nextprevbutton-mobile {
    width: max-content !important;
    display: flex !important;
    flex-direction: column;
    /* margin: auto auto !important; */
  }
  .nextprevbutton {
    display: none !important;
  }
  .map-container {
    padding: 0 20px;
  }
  .youtube-width {
    width: 100%;
    height: 300px;
  }
  .text-editor {
    padding: 0 5% !important;
  }
  .visit-cam::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 2px;
    background-color: darkgreen;
  }
}
