#site-timeline {
	position: relative;
	/* max-width: 920px; */
	width: 100%;
	margin: 0 auto;
  margin-top: 30px;
}
.heading-img{
    background-image: url("../../img/initiativebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 350px;
}
.heading-img h1{
    font-size: 52px !important;
    text-align: start;
    
}
.timeline-article .site-center-line {
	position: absolute;
	width: 3px;
	height: 100%;
	top: 10%;
	left: 50%;
	margin-left: -2px;
	z-index: -1;
	bottom: 5%;
}
.year__ {
	display: inline-block;
}
.timeline-article.odd .site-center-line {
	background: #45a049;
}
.timeline-article.even .site-center-line {
	background: darkgreen;
}
.timeline-article {
	width: 100%;
	position: relative;
	overflow: hidden;
	margin: 0;
	margin-top: -2%;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
	max-width: 47%;
	width: 100%;
}
.timeline-article .content-left,
.timeline-article .content-right {
	position: relative;
	width: auto;
	padding: 60px 25px 20px;
}
.timeline-title {
	margin-top: 0;
}
.timeline-article p {
	padding: 0;
	font-weight: 400;
	color: #242424;
	font-size: 14px;
	line-height: 24px;
	position: relative;
}
.timeline-article .content-left-container {
	float: left;
}
h5.timeline-year {
	margin: 10px 0 0 0;
	display: inline-block;
	padding: 10px 25px;
	border-radius: 21px;
	color: #fff;
}
.timeline-article.odd .year__ {
	float: left;
}
.timeline-article.odd h5.timeline-year {
	background: #45a049;
}
.timeline-article.even .year__ {
	float: right;
}
.timeline-article.even h5.timeline-year {
	background: darkgreen;
}
.line {
	position: absolute;
	width: 55px;
	height: 2px;
	top: 30px;
	margin-left: 0;
	z-index: 0;
}
.timeline-article.odd .line {
	left: 50%;
	background: #45a049;
}
.timeline-article.even .line {
	right: 50%;
	background: darkgreen;
}
.owl-carousel {
	position: relative;
	width: auto;
	padding: 60px 25px 20px;
}
.timeline-article .content-right-container {
	float: right;
}
.timeline-article .meta-dot {
	position: absolute;
	top: 20px;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-left: -10px;
	border-radius: 100%;
}
.timeline-article.odd .meta-dot {
	background: #45a049;
}
.timeline-article.even .meta-dot {
	background: darkgreen;
}
.global-img{
	width: 100%;
	aspect-ratio: 12/4;
	object-fit: cover;
	
}
.global-breadcomb{
	position: relative;
}
.global-heading{
	width: 100%;
	padding: 0 10%;
	position: absolute;
	top: 0;
	height: 100%;
	color: white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color:rgba(0, 0, 0, 0.5) ;
}
.details-link{
	color: darkgreen !important;
	font-weight: 600;
}
/* .global-heading>h1{
	vertical-align: middle;
} */
@media only screen and (max-width: 768px) {
	.heading-img h1{
        font-size: 52px !important;
       margin-top: 20%;
    
    }
    .heading-img{
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../img/initiativebg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
        height: 350px;
    }
	.line {
		position: absolute;
		width: 55px;
		height: 2px;
		/* top: 28%; */
		margin-left: 0;
		z-index: 0;
	}
	.timeline-article.odd .line {
		left: 8%;
		background: #45a049;
	}
	.timeline-article.even .line {
		left: 8%;
		background: darkgreen;
	}
	.year__{
		left: 0;
		width: 100%;
	}
	.global-img{
		width: 100%;
		aspect-ratio: 16/9;
		object-fit: cover;
		
	}
	.global-heading>h1{
		font-size: x-large;
	}
	.timeline-article .site-center-line  {
		margin-left: 0;
		left: 28px !important;
		top: 20px;
	}
	.timeline-article .meta-dot {
		margin-left: 0;
		left: 20px;
	}
	.timeline-article .content-left-container,
	.timeline-article .content-right-container {
		max-width: 100%;
		width: auto;
		float: none;
		margin-left: 75px;
		min-height: 53px;
	}
	.timeline-article .content-left-container {
		margin-bottom: 20px;
	}
	.timeline-article .content-left,
	.timeline-article .content-right {
		padding: 10px 25px;
		min-height: 65px;
	}
	.timeline-article .content-left:before {
		content: "\f0d9";
		right: auto;
		left: -8px;
	}
	.timeline-article.even .year__ {
		float: unset !important;
	}
	.timeline-article .content-right:before {
		display: none;
	}
}
@media (max-width: 768px) {
	.timeline-article.mobile-view {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  text-align: center;
	}
  
	.timeline-article.mobile-view .year__ {
	  order: 1;
	  margin-bottom: 15px;
	}
  
	.timeline-article.mobile-view .carousel-container {
	  order: 2;
	  width: 100%;
	  margin-bottom: 15px;
	}
  
	.timeline-article.mobile-view .content-container {
	  order: 3;
	  padding: 10px;
	}
  
	.timeline-year {
	  font-size: 1.2rem;
	  margin-bottom: 10px;
	}
  
	.timeline-title {
	  font-size: 1.5rem;
	  margin-top: 10px;
	}
  }
  