/* src/components/ProfileCard.css */

.profile-card {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.card {
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
}

.bg-img {
  position: relative;
  background-image: url("../../img/bgprofile.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* 
.mt-5 {
  margin-top: 0% !important;
} */

.pt-3 {
  padding-top: 0% !important;
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4); 
} */

.content {
  position: relative;
  text-align: left;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.6); Optional: add background color for readability */
  z-index: 1;
}

.card-body {
  padding-left: 20px;
  padding-right: 20px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  color: #6c757d;
}



.img-fluid {
  max-height: 100%;
  object-fit: cover;
}

.profile-photo {
  /* position: relative; */
  bottom: 2.5%;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
}

.commitment {
  height: 50px;
}
.profile-card-desc{
  margin: 8% 0 0 0 !important;
  /* padding: 4% 0 0 0 !important; */
}
.priority-list {
  list-style-type: none;
  padding-left: 0;
}

/* .priority-item {
  max-height: 200px !important;
} */

.priority-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.profile-card .priority-list {
 overflow-x: scroll;
 width: 55vw;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .profile-card .priority-item {
  position: relative;
  margin-bottom: 1rem;
} */
.priority-title{
  font-size: calc(1.375rem + 1.5vw) !important;
}
.profile-card .priority-item .priority-description {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255);
  color: #000;
  padding: 0.5rem;
  border-radius: 0.25rem;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* white-space: nowrap; */
  white-space: normal; /* Ensure text wraps if necessary */
  overflow-y: scroll;
  max-height: 230px;
  z-index: 99990;
  width: 300px;
}
/* Custom scroll bar for Webkit browsers */
.profile-card .priority-item .priority-description::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  scrollbar-width: thin;
}

.profile-card .priority-item .priority-description::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track color */
  border-radius: 10px;
}

.profile-card .priority-item .priority-description::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4); /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
}

/* For Firefox */
.profile-card .priority-item .priority-description {
  scrollbar-width: none; /* Thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1); /* Scrollbar and track color */
}
.priority-list::-webkit-scrollbar {
  /* width: 3px !important; Width of the scrollbar */
  scrollbar-width: none;
}
.priority-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track color */
  border-radius: 10px;
}
.priority-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4); /* Scrollbar color */
  border-radius: 10px; /* Rounded edges */
}
.tagline-width {
  width: 70% !important;
}

.profile-card .priority-item:hover .priority-description {
  display: block;
}

.small-card-height {
  height: 660px;
}
.empty-priority-item {
  visibility: hidden; /* Hide the empty items, but they still take up space */
  height: 50px; /* Match the height of a priority item */
}

.card-body-custom {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100%; /* Ensure the card body takes full height */
}

.priority-item {
  /* width: 20%; */
  height: 180px;
  min-height: 50px; /* Ensure a consistent height for each priority item */
}
.hidden-btn{
  display: none !important;
}
.know-more-card-long{
  font-size:larger !important;
  font-weight: 500 !important;
 
}
@media screen and (max-width: 768px) {
 
  .profile-photo{
    padding-left: 25%;
  }
  .priority-image{
    height: 80px;
    width: 80px;
  }
  .priority-head{
    font-size: calc(1.0rem + 1.2vw) !important;
  }
  .know-more-card-long{
    margin-top: 0.85rem !important;
    font-size: unset !important;
  }
  .priority-location svg{
    height: 18px;
    width: 18px;
  }
  .priority-location{
    padding-top: 0.4rem !important;
    font-size: calc(0.8rem + 1.0vw) !important;
  }
  .priority-title{
    font-size: calc(1.0rem + 1.2vw) !important;
  }
 .priority-text{
  display: none;
 }
 .priority-item{
  height: auto;
 }
  .bg-img {
    background-image: url("../../img/bgprofilemobile.webp");
   background-position: center;
   background-size: cover;
  }  
  /* .profile-photo {
    margin-bottom: 150px;
  } */
  .profile-card .priority-list {
    width: 77vw;
  }

  .tagline-width {
    width: 100% !important;
    padding: 10px 8px !important;
  }

  .profile-card-desc {
    /* display: none; */
    margin: 7.5% 0 !important;
    padding-right: 0 !important;
  }

  .profile-card {
    min-height: 735px;
    margin-top: 1% !important;
  }

  .small-card-height {
    height: 645px;
  }

  .card-body-custom {
    padding: 0 10px !important;
  }

  .mobile-padding {
    padding: 5% 5% !important;
  }
  
  .commitment {
    
    height: auto;
  }

  .commitment > h4 {
    font-size: calc(0.85rem + 1.2vw) !important;
    
  }

  .commitment-mobile {
    order: 1; /* Move commitment below the image */
  }

  .profile-photo-mobile {
    order: 2; /* Move image above the commitment */
  }
}
