/* Preload the font to improve performance and prevent layout shifts */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */

body {
  font-family: 'Poppins', sans-serif !important;
  
  /* Ensuring text renders smoothly */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* Prevent layout shifts by setting a fallback font */
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
