.btn-custom-primary{
  color: #0A4D20FF !important; /* success-800 */
  background-color: #8CC14AFF !important;
  border: none; 
  border-radius: 4px;
  font-weight: bold;
}
.btn-custom-primary:hover{
  border: 1px solid #8CC14AFF !important;
  background-color:unset !important;
}
.btn-custom-outline-primary {
  color: #0A4D20FF !important; /* success-400 */
  background-color: transparent !important;
  border: 2px solid #8CC14AFF !important; /* Border with the primary color */
  border-radius: 4px;
  font-weight: bold;
}

.btn-custom-outline-primary:hover {
  color: #0A4D20FF !important; /* success-800 */
  background-color: #8CC14AFF !important;
  border: 2px solid #8CC14AFF !important;
}
.top-header{
  /*  */
  
  position: fixed;
  top: 28%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  /* justify-content: end; */
}
.top-header a{
  background-color: #0F8240FF;
  padding: 10px;
  /* border-radius: 20%; */
}
a{
  color: unset !important;
  text-decoration: unset !important;
}
.bg-custom-primary{
  background-color: #0F8240FF !important;
}
.social-media-btn{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 40px;
  height: 40px;
  margin: 5px;
  outline: none;
  border: none;
  /* background: #d6d6d6; */
  box-shadow: 3px 3px 7px #3a3a3a, -5px -5px 10px #28282800;
  border-radius: 50%;
  transition: 0.2;
}

.social-media-btn:hover {
  cursor: pointer;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.21);
  }
  to {
    transform: scale(1);
  }
}


.ql-container{
  font-size: 17px !important;
}
@media screen and (max-width: 768px) { 
  .top-header{
   display: none;
  }
}