.nav-item{
    font-weight: bold !important;
    font-size: 18px;
    
}
.donate-btn{
    letter-spacing: 2px;
    font-size:large !important;
    font-weight: 500 !important;
    --bs-nav-link-padding-y :0;
}